<template>
  <div class="dashboard">
    <DefaultLayout>
      <slot></slot>
      <FilterBox :roles="roles" />
      <b-table
        class="custom-table"
        :items="items"
        :busy="loading"
        :fields="fields"
        responsive
        :sticky-header="true"
        show-empty
        :tbody-transition-props="transProps"
      >
        <template #table-busy>
          <div class="text-center my-2">
            <b-spinner class="align-middle" />
          </div>
        </template>

        <template #empty>
          <div class="text-center p-4">
            {{ $t("GLOBAL.NO_DATA") }}
          </div>
        </template>
        <template #cell(id)="data">
          <div>
            {{ data.index + 1 }}
          </div>
        </template>
        <template #cell(name)="data">
          <div>
            <div class="table-profile">
              <img :src="data.item.avatar != null ? data.item.avatar.url : default_avatar" />
              <p>{{ data.item.name }} - {{ data.item.type }}</p>
            </div>
          </div>
        </template>
        <template #cell(role)="data">
          <div>
            <div>
              <p class="role-item">{{ data.item.type }}</p>
            </div>
          </div>
        </template>
        <template #cell(otherPermissions)="data">
          <div>
            <div>
              <p class="role-item">{{ data.item.otherPermissions }}</p>
            </div>
          </div>
        </template>

        <template #cell(active)="data">
          <!-- <div>
            <div class="table-profile">
              <ToggleButton
                v-model="toggleVal"
                :value="data.item.active"
                :sync="true"
                :width="30"
                :height="15"
                color="#019078"
                @click="toggleVal = !toggleVal"
              />
            </div>
          </div> -->
          <div>
            <ToggleButton :data="data" />
          </div>
        </template>
        <template #cell(pending)="data">
          <!-- <div>
            <div class="table-profile">
              <ToggleButton
                v-model="toggleVal"
                :value="data.item.active"
                :sync="true"
                :width="30"
                :height="15"
                color="#019078"
                @click="toggleVal = !toggleVal"
              />
            </div>
          </div> -->
          <div v-if="data.item.type == 'driver'">
            <PendingToggleButton :data="data" />
          </div>
        </template>
        <template #cell(actions)="data">
          <div>
            <div class="table-actions">
              <img src="@/assets/images/icons/table-delete.svg" @click="handleDelete(data.item)" />
              <img src="@/assets/images/icons/table-edit.svg" @click="handleEdit(data.item)" />
            </div>
          </div>
        </template>
      </b-table>
      <div class="overflow-auto">
        <CustomPagination :current_page="current_page" :requestStatus="requestStatus" :links="links" @paginatePrev="paginatePrev" @paginateNext="paginateNext" @getPagination="getPagination"/>
      </div>
      <!-- <Pagination
        :current-page="current_page"
        :total-items="total_items"
        :per-page="per_page"
        class="pagination"
      />   -->
      <EditUserDetails />
      <AddUser @saveChanges="handleAddMember" />
      <ModalDetails :user="user" />
    </DefaultLayout>
  </div>
</template>

<script>
import FilterBox from "@/components/Shared/FilterBox/index.vue"
import EditUserDetails from "@/components/Shared/EditUserDetails/index.vue"
import AddUser from "@/components/Shared/AddUser/index.vue"
import CustomPagination from "@/components/Shared/CustomPagination/index.vue"
import { getUsersRequest, getUserByIdRequest, deleteUser, createUser } from "@/api/users.js"
import ToggleButton from "../../ToggleButton/index.vue"
import PendingToggleButton from "../../PendingToggleButton/index.vue"
import { mapActions } from "vuex"
import ModalDetails from "../../../Shared/EditUserDetails/index.vue"

export default {
  props: {
    type: {
      type: String
    }
  },
  components: {
    FilterBox,
    ToggleButton,
    PendingToggleButton,
    EditUserDetails,
    AddUser,
    ModalDetails,
    CustomPagination
  },
  data() {
    return {
      toggleVal: false,
      users: [],
      user: {},
      transProps: {
        name: "flip-list"
      },
      loading: false,
      default_avatar: require("@/assets/images/user.png"),
      requestStatus: 'all',
      links: '',
      current_page: 1,
      total_items: 0,
      per_page: 0,
      roles: [
        {
          id: 1,
          name: "role 1"
        },
        {
          id: 2,
          name: "role 2"
        },
        {
          id: 3,
          name: "role 3"
        }
      ],
      fields: [
        { key: "id", label: "#", sortable: false },
        {
          key: "name",
          label: this.$t("GLOBAL.USER_NAME"),
          sortable: false
        },
        {
          key: "phone",
          label: this.$t("GLOBAL.PHONE_NUMBER"),
          sortable: false
        },
        {
          key: "email",
          label: this.$t("GLOBAL.EMAIL"),
          sortable: false
        },
        // {
        //   key: "job",
        //   label: this.$t("GLOBAL.JOB"),
        //   sortable: false
        // },
        {
          key: "role",
          label: this.$t("GLOBAL.ROLE"),
          sortable: false
        },
        // {
        //   key: "otherPermissions",
        //   label: this.$t("GLOBAL.OTHER_PERMISSIONS"),
        //   sortable: false
        // },
        {
          key: "active",
          label: this.$t("GLOBAL.ACTIVE"),
          sortable: false
        },
        {
          key: "pending",
          label: this.$t("GLOBAL.PENDING"),
          sortable: false
        },
        {
          key: "actions",
          label: this.$t(""),
          sortable: false
        }
      ],
      items: []
    }
  },
  mounted() {
    this.getUsers()
  },
  methods: {
    ...mapActions(["ShowToast"]),
    handleEdit(obj) {
      this.ApiService(getUserByIdRequest(obj.id))
        .then((res) => {
          this.user = res.data.data
          this.$bvModal.show("edit-user-details")
        })
        .catch((err) => {
          this.ShowToast({
            title: this.$t("Error"),
            type: "danger",
            message: this.$t(err)
          })
        })
    },
    handleDelete(obj) {
      this.ApiService(deleteUser(obj.id)).then((res) => {
        if (res.data.status == "success") {
          this.items.splice(this.items.indexOf(obj), 1)
          this.ShowToast({
            title: this.$t("Delete User"),
            type: "danger",
            message: this.$t("Deleted Success")
          })
          return res
        }
      })
    },
    handleAddMember(data) {
      console.log(data)
      this.ApiService(createUser(data))
      .then(res => {
        if(res.data.status == "success"){
          this.$bvModal.hide("add-user")
          location.reload()
        }
      })
    },
    handleAddUSer() {
      this.$bvModal.show("add-user")
    },
    getUsers() {
      // console.log(this.type)
      this.loading = true
      this.ApiService(getUsersRequest(1, this.type))
        .then((res) => {
          if (res.data.status != "success") {
            this.ShowToast({
              title: this.$t("GLOBAL.GLOBAL_FAILED"),
              type: "danger",
              message: res.data.message || this.$t("GLOBAL.GLOBAL_FAILED")
            })
            return res
          }
          console.log(res.data)
          this.items = res.data.data.data
          this.current_page = res.data.data.current_page
          this.total_items = res.data.data.total
          this.per_page = res.data.data.per_page
          this.links = res.data.data.links.slice(1, res.data.data.links.length - 1)
          this.activeLinks = this.links.slice(0, 4)
        })
        .finally(() => {
          this.loading = false
        })
    },
    getPagination(v){
      this.loading = true
      this.ApiService(getUsersRequest(v.page))
      .then(res => {
        if(res.data.status == 'success'){
          this.items = res.data.data.data
          this.current_page = res.data.data.current_page
          this.total_items = res.data.data.total
          this.per_page = res.data.data.per_page
          this.links = res.data.data.links.slice(1, res.data.data.links.length - 1)
          this.activeLinks = this.links.slice(0, 4)
          this.loading = false
        }
      })
    },
    paginateNext(v){
      if(v.c_page != this.links.length){
        this.loading = true
        this.ApiService(getUsersRequest(v.c_page+1))
        .then(res => {
          if(res.data.status == 'success'){
            this.items = res.data.data.data
            this.current_page = res.data.data.current_page
            this.total_items = res.data.data.total
            this.per_page = res.data.data.per_page
            this.links = res.data.data.links.slice(1, res.data.data.links.length - 1)
            this.loading = false
          }
        })
      }else{
        return false
      }
    },
    paginatePrev(v){
      if(v.c_page != 1){
        this.loading = true
        this.ApiService(getUsersRequest(v.c_page-1))
        .then(res => {
          if(res.data.status == 'success'){
            this.items = res.data.data.data
            this.current_page = res.data.data.current_page
            this.total_items = res.data.data.total
            this.per_page = res.data.data.per_page
            this.links = res.data.data.links.slice(1, res.data.data.links.length - 1)
            this.loading = false
          }
        })
      }else{
        return false
      }
    },
  }
}
</script>

<style scoped lang="scss">
@import "./index.scss";
</style>
