<template >
  <div>
    <div class="table-profile">
      <ToggleButton
        v-model="toggleVal"
        :value="1"
        :sync="true"
        :width="30"
        :height="15"
        color="#019078"
        @click="toggleVal = !toggleVal"
        @change="toggleStatus(toggleVal)"
      />
    </div>
  </div>
</template>

<script>
import { ToggleButton } from "vue-js-toggle-button"
import { changeUserStatus } from "@/api/users.js"
import { mapActions } from "vuex"
export default {
  props: {
    data: {
      type: Object
    }
  },
  components: {
    ToggleButton
  },
  data() {
    return {
      toggleVal: true,
      request_data:{
        status: '',
        id: this.data.item.id
      }
    }
  },
  mounted(){
    (this.data.item.status == 1)?this.toggleVal = true:this.toggleVal = false
  },
  methods: {
    ...mapActions(["ShowToast"]),
    toggleStatus(value){
      this.request_data.status = value;
      this.ApiService(changeUserStatus(this.data.item.id, this.request_data))
      .then((res) => {
        if(res.data.status == 'success'){
          this.ShowToast({
              title: this.$t("Update Status"),
              type: "success",
              message: this.$t("Update Success")
          })
        }
      }).catch((err) => {
        this.ShowToast({
              title: this.$t("GLOBAL.GLOBAL_SUCCESS"),
              type: "danger",
              message: this.$t("USER.EDIT_SUCCESS")
          })
      })
    }
  }
}
</script>