<template>
  <b-modal id="add-user" hide-footer size="lg" centered hide-header>
    <div :class="$i18n.locale == 'en' ? '' : 'rtl'">
      <div class="w-100 info-container">
        <div class="header">
          <h3>{{ $t("ACCOUNTS.ADD_USER") }}</h3>
          <p>{{ $t("ACCOUNTS.ADD_USER_SUBTITLE") }}</p>
        </div>
        <div class="form">
          <b-row>
            <b-col>
              <b-form-group class="mb-3">
                <SelectSearch
                  v-model="formValues.status"
                  :label="$t('ACCOUNTS.STATUS')"
                  :name="$t('ACCOUNTS.STATUS')"
                  :options="statusList"
                  :get-option-label="(option) => option.name"
                  :reduce="(option) => option.id"
                  rules="required"
                />
              </b-form-group>
            </b-col>
            <b-col>
              <b-form-group class="mb-3">
                <SelectSearch
                  v-model="formValues.type"
                  :label="$t('ACCOUNTS.ROLE')"
                  :name="$t('ACCOUNTS.ROLE')"
                  :options="roles"
                  :get-option-label="(option) => option.name"
                  :reduce="(option) => option.id"
                  rules="required"
                />
              </b-form-group>
            </b-col>
          </b-row>
          <b-row>
            <b-col>
              <b-form-group class="mb-3">
                <TextField
                  v-model="formValues.name"
                  :label="$t('ACCOUNTS.MEMBER_NAME')"
                  :name="$t('ACCOUNTS.MEMBER_NAME')"
                  rules="required"
                />
              </b-form-group>
            </b-col>
            <b-col>
              <b-form-group class="mb-3">
                <TextField
                  v-model="formValues.phone"
                  :label="$t('SETTING.PHONE_NUMBER')"
                  :name="$t('SETTING.PHONE_NUMBER')"
                  rules="required"
                />
              </b-form-group>
            </b-col>
          </b-row>
          <b-row>
            <b-col>
              <b-form-group>
                <TextField
                  v-model="formValues.description"
                  :label="$t('ACCOUNTS.DESCRIPTION')"
                  :name="$t('ACCOUNTS.DESCRIPTION')"
                  rules="required"
                />
              </b-form-group>
            </b-col>
          </b-row>
          <b-row>
            <b-col>
              <b-form-group>
                <TextField
                  v-model="formValues.email"
                  :label="$t('ACCOUNTS.EMAIL')"
                  :name="$t('ACCOUNTS.EMAIL')"
                  rules="required|email"
                />
              </b-form-group>
            </b-col>
          </b-row>
          <b-row>
            <b-col>
              <b-form-group class="mb-3">
                <TextField
                  v-model="formValues.password"
                  :label="$t('AUTHENTICATION.PASSWORD')"
                  :name="$t('AUTHENTICATION.PASSWORD')"
                  rules="required"
                />
              </b-form-group>
            </b-col>
            <b-col>
              <b-form-group class="mb-3">
                <TextField
                  v-model="formValues.confirm_password"
                  :label="$t('GLOBAL.CONFIRM_CHANGE_PASSWORD')"
                  :name="$t('GLOBAL.CONFIRM_CHANGE_PASSWORD')"
                  rules="required"
                />
              </b-form-group>
            </b-col>
          </b-row>
          <b-row>
            <b-col>
              <b-form-group class="mb-3">
                <SelectSearch
                  v-model="formValues.statusId"
                  :label="$t('ACCOUNTS.PERMISSIONS')"
                  :name="$t('ACCOUNTS.PERMISSIONS')"
                  :options="permissionsList"
                  :get-option-label="(option) => option.name"
                  :reduce="(option) => option.id"
                  rules="required"
                />
              </b-form-group>
            </b-col>
          </b-row>
          <b-row class="mt-3">
            <b-col>
              <Button @click="handleSaveChanges">{{ $t("GLOBAL.SUBMIT_ADD_MEMBER") }}</Button>
              <Button custom-class="mx-3 cancel-btn" @click="handleCancel">{{
                $t("GLOBAL.CANCEL")
              }}</Button>
            </b-col>
          </b-row>
        </div>
      </div>
    </div>
  </b-modal>
</template>
<script>
export default {
  props: {},
  data() {
    return {
      formValues: {
        type: 'admin',
        status: 1,
        name: "",
        description: "",
        email: "",
        permisionId: 1
      },
      statusList: [
        {
          id: 1,
          name: "نشط"
        },
        {
          id: 2,
          name: "غير نشط"
        }
      ],
      roles: [
      {
          id: 'admin',
          name: "مدير"
        },
        {
          id: 'driver',
          name: "سائق"
        }
      ],
      permissionsList: [
        {
          id: 1,
          name: "مراجعة"
        },
        {
          id: 2,
          name: "متابعة"
        }
      ]
    }
  },
  methods: {
    handleCancel() {
      this.$bvModal.hide("add-user")
    },
    handleSaveChanges() {
      this.$emit("saveChanges", this.formValues)
    }
  }
}
</script>
<style lang="scss" scoped>
@import "./index.scss";
</style>
